import RestApi, { trainingElearningServiceBaseUrl } from '../config/api_config'

export default {
  computed: {
    hasDropdownLoadedTrainingElearningService () {
      return this.$store.state.TrainingElearning.commonObj.hasDropdownLoaded
    },
    fiscalYearList () {
      return this.$store.state.commonObj.fiscalYearList
    }
  },
  watch: {
    hasDropdownLoadedTrainingElearningService: function (newValue) {
      if (!newValue) {
        this.loadDropdownCommonConfig()
      }
    },
    fiscalYearList (newValue) {
      this.setCurrentFiscalYear(newValue)
    }
  },
  created () {
    this.loadDropdownCommonConfig()
  },
  methods: {
    loadDropdownCommonConfig () {
      RestApi.getData(trainingElearningServiceBaseUrl, 'common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('TrainingElearning/mutateTrainingElearningCommonProperties', {
            hasDropdownLoaded: true,
            trainingTypeList: response.data.trainingTypeList,
            trainingCategoryList: response.data.trainingCategoryList,
            trainingTitleList: response.data.TrainingTitleList,
            roomTypeList: response.data.RoomTypeList,
            roomRentList: response.data.roomRentList,
            accomodationSetupList: response.data.accomodationSetupList,
            courseDocumentSetupList: response.data.courseDocumentSetupList,
            faqSetupList: response.data.faqSetupList,
            trainingQuarterSetupList: response.data.trainingQuarterSetupList,
            foodPackageList: response.data.foodPackageList,
            traineeEvaluationList: response.data.traineeEvaluationList,
            venus: response.data.venus,
            courseList: response.data.courseList,
            trainerEvaluationList: response.data.trainerEvaluationList,
            batchList: response.data.batchList,
            // personalInfoList: response.data.personalInfoList,
            courseModuleList: response.data.courseModuleList,
            guestHouseList: response.data.guestHouseList,
            roomList: response.data.roomList,
            gradingStatus: response.data.gradingStatus,
            floorList: response.data.floorList,
            trainingScheduleSetup: response.data.trainingScheduleSetup,
            sessonType: response.data.sessonType,
            trainingReportHeadList: response.data.trainingReportHeadList,
            nominatedOrgList: response.data.nominatedOrgList,
            evaluationGradingList: response.data.evaluationGradingList
          })
          this.$store.dispatch('TrainingElearning/localizeDropdown', { value: this.$i18n.locale })
        }
      })
    },
    setCurrentFiscalYear (fiscalYearList) {
      if (fiscalYearList.length === 0) {
        return
      }
      const currentDate = new Date()
      const [month, year] = [currentDate.getMonth() + 1, currentDate.getFullYear()]
      /** Define the year position  */
      const yearPosition = month < 7 ? 5 : 0
      const yearStr = `${year}`
      let currentFiscalYearId = 0
      fiscalYearList.forEach(element => {
        if (element.text_en.indexOf(yearStr) === yearPosition) {
          currentFiscalYearId = element.value
        }
      })
      this.$store.dispatch('TrainingElearning/setCurrentFiscalYearId', currentFiscalYearId)
    }
  }
}
